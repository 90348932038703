import React, { useState } from 'react';

//Constants for CAT_DAYS and MONTHS

const CAT_DAYS = [
  { name: 'Solara', freq: 963, note: 'B5', color: '#9333EA', chakra: '(Crown)' },
  { name: 'Celesta', freq: 852, note: 'Ab5', color: '#3B82F6', chakra: '(Angel)' },
  { name: 'Lunata', freq: 741, note: 'Gb5', color: '#4F46E5', chakra: '(Third Eye)' },
  { name: 'Lyrata', freq: 639, note: 'Eb5', color: '#06B6D4', chakra: '(Throat)' },
  { name: 'Corona', freq: 528, note: 'C5', color: '#22C55E', chakra: '(Heart)' },
  { name: 'Fortuna', freq: 417, note: 'Ab4', color: '#EAB308', chakra: '(Plexus)' },
  { name: 'Erotica', freq: 396, note: 'G4', color: '#F97316', chakra: '(Sacral)' },
  { name: 'Gaiata', freq: 285, note: 'Db4', color: '#EF4444', chakra: '(Root)' },
  { name: 'Thanata', freq: 174, note: 'F3', color: '#1F2937', chakra: '(Death)' }
];

const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const getWeekday = (dayNumber) => {
  const baseDate = new Date(2024, 11, 21); // Dec 21, 2024 is our start date
  const date = new Date(baseDate.getTime() + (dayNumber - 1) * 24 * 60 * 60 * 1000);
  return WEEKDAYS[date.getDay()];
};

const MONTHS = [
  { name: 'Saha I', baseName: 'Saha', dateRange: 'Dec 21 - Jan 22', startDay: 1, offset: 0, baseFreq: 963 },
  { name: 'Shuddha I', baseName: 'Shuddha', dateRange: 'Jan 23 - Feb 24', startDay: 34, offset: 3, baseFreq: 639 },
  { name: 'Swadhi I', baseName: 'Swadhi', dateRange: 'Feb 25 - Mar 29', startDay: 67, offset: 6, baseFreq: 396 },
  { name: 'Mahasukha', dateRange: 'Mar 30 - May 1', startDay: 100, offset: 0, baseFreq: 852 },
  { name: 'Anah', dateRange: 'May 2 - Jun 3', startDay: 133, offset: 3, baseFreq: 528 },
  { name: 'Mula', dateRange: 'Jun 4 - Jul 6', startDay: 166, offset: 6, baseFreq: 285 },
  { name: 'Ajna', dateRange: 'Jul 7 - Aug 8', startDay: 199, offset: 0, baseFreq: 741 },
  { name: 'Mani', dateRange: 'Aug 9 - Sep 10', startDay: 232, offset: 3, baseFreq: 417 },
  { name: 'Siva', dateRange: 'Sep 11 - Oct 13', startDay: 265, offset: 6, baseFreq: 174 },
  { name: 'Shuddha II', baseName: 'Shuddha', dateRange: 'Oct 14 - Nov 15', startDay: 298, offset: 0, baseFreq: 639 },
  { name: 'Swadhi II', baseName: 'Swadhi', dateRange: 'Nov 16 - Dec 18', startDay: 331, offset: 3, baseFreq: 396 },
  { name: 'Saha II', baseName: 'Saha', dateRange: 'Dec 19 - Dec 24', startDay: 364, offset: 6, baseFreq: 963 }
];

const ASTRONOMICAL_EVENTS = {
  solarEvents: [
    { date: '2025-03-20', type: 'Spring Equinox', day: 90 },
    { date: '2025-06-21', type: 'Summer Solstice', day: 183 },
    { date: '2025-09-22', type: 'Autumn Equinox', day: 276 },
    { date: '2025-12-21', type: 'Winter Solstice', day: 366 }
  ],
  stellarEvents: [
    { date: '2025-06-29', type: 'Midnight Crown', day: 191 },
    { date: '2025-07-29', type: "Lion's Gate", day: 221 }
  ],
  zodiacTransitions: [
    { sign: '♒', day: 30 },
    { sign: '♓', day: 60 },
    { sign: '♈', day: 90 },
    { sign: '♉', day: 120 },
    { sign: '♊', day: 150 },
    { sign: '♋', day: 183 },
    { sign: '♌', day: 214 },
    { sign: '♍', day: 245 },
    { sign: '♎', day: 276 },
    { sign: '♏', day: 307 },
    { sign: '♐', day: 337 },
    { sign: '♑', day: 366 }
  ]
};

const DAY_MEANINGS = {
  'Saha': {
    Solara: 'Divine in heaven meets divine on Earth, pure crown activation',
    Lyrata: 'Poetic inspiration, divine voice, cosmic harmonies',
    Erotica: 'Divine sexual energy, transcendent orgasmic bliss',
    Celesta: 'High level communion with Guardian Angel, divine guidance',
    Corona: 'Divinity in the heart, divine compassion',
    Fortuna: 'Grand blessings for unlimited material wealth',
    Gaiata: 'Divine power of life force in Earth, heaven on Earth',
    Lunata: 'Prophetic or divinely inspired dreams',
    Thanata: 'Soul returns to heaven, divine void'
  },
  'Shuddha': {
    Solara: 'Artistic, architectural, literary expression of divine order',
    Lyrata: 'Creativity amplified, unimpeded expression of creative manifestation',
    Erotica: 'Love poetry, love songs, music, expressing erotic love',
    Celesta: 'Inspiration, angelic guidance in self-expression',
    Corona: 'Sincere, authentic self expression, manifesting heart\'s desires',
    Fortuna: 'Embodiment of poetry, dance, creative or artistic success',
    Gaiata: 'Communication with nature spirits, animals, elemental voices',
    Lunata: 'Communication with unconscious mind, dream speaking',
    Thanata: 'Communication with ancestor spirits, voices of the underworld'
  },
   'Swadhi': {
    Solara: 'Love Goddess/God energy, transcendent sexual empowerment',
    Lyrata: 'Charisma, rock star power, seductive language, oral pleasure',
    Erotica: 'Fully embodied erotic empowerment, unlimited orgasmic potential',
    Celesta: 'Angelic romance, twin flame connections, guided sexual healing',
    Corona: 'Sexual fulfillment in true love, heart-opening orgasms',
    Fortuna: 'Abundant sexual pleasure, successful sex work, multiple experiences',
    Gaiata: 'Primal lust, high fertility, pure physical attraction',
    Lunata: 'Sexual fantasies, erotic dreams, unconscious desires',
    Thanata: 'Release from sexual trauma, exploring taboos, shadow sexuality'
  },
  'Maha': {
    Solara: 'Angelic communication with divine power, cosmic understanding',
    Lyrata: 'Oracular speech, dialogue with Guardian Angel, channeling',
    Erotica: 'Angelic guidance in sexual affairs, erotic love with celestial beings',
    Celesta: 'Full open channel to angelic realm, becoming one with Guardian',
    Corona: 'Angelic emotional intelligence, Christ consciousness, forgiveness',
    Fortuna: 'Great fortune, material blessings, angelic business guidance',
    Gaiata: 'Spiritual sanctuary on Earth, guided evolution, elemental teachers',
    Lunata: 'Mystical visions, inspired dreams, higher consciousness integration',
    Thanata: 'Ego Death, Guidance through underworld, angelic ancestor spirits, fallen angels'
  },
 'Anah': {
    Solara: 'Divine love manifesting, heart filled with celestial light',
    Lyrata: 'Songs of the heart, poetry of pure love, expressing deep feelings',
    Erotica: 'Heart-centered sexuality, tantric love, deep intimacy',
    Celesta: 'Guardian Angel heart connection, unconditional love',
    Corona: 'Pure heart activation, complete emotional opening',
    Fortuna: 'Abundance through love, heart\'s desires manifesting',
    Gaiata: 'Earth-heart connection, love of nature, physical affection',
    Lunata: 'Dream love, emotional intuition, heart visions',
    Thanata: 'Healing through grieving, Heart breaking open through loss, emotional release/rebirth'
  },
  'Mula': {
    Solara: 'Divine light grounding into physical form',
    Lyrata: 'Earth songs, drumming, voice of the land, Indigenous Traditions',
    Erotica: 'Primal sexuality, kundalini rising from root',
    Celesta: 'Guardian protection of physical form',
    Corona: 'Heart energy grounding into body, Spiritual Healing of the Body',
    Fortuna: 'Material manifestation, physical abundance',
    Gaiata: 'Pure earth connection, deep grounding',
    Lunata: 'Earth dreams, cave visions, underground wisdom',
    Thanata: 'Return to earth, composting, physical dissolution'
  },
  'Ajna': {
    Solara: 'Divine vision, seeing ultimate truth',
    Lyrata: 'Visionary poetry, songs of prophecy, Psychedelic art',
    Erotica: 'Tantric vision states, Seeing divine in lover',
    Celesta: 'Guardian Angel vision guidance',
    Corona: 'Heart-centered vision, seeing with love',
    Fortuna: 'Seeing paths to abundance, fortune vision',
    Gaiata: 'Nature vision, seeing elemental beings',
    Lunata: 'Pure vision states, clairvoyance, remote viewing',
    Thanata: 'Visions of death/void, seeing between worlds'
  },
  'Mani': {
    Solara: 'Divine will manifesting, Cosmic power generation',
    Lyrata: 'Voice of power, commanding presence',
    Erotica: 'Sexual empowerment, Erotic Confidence, Virility, Sexual Stamina',
    Celesta: 'Guided use of personal power',
    Corona: 'Heart-centered power, compassionate strength',
    Fortuna: 'Power of manifestation, confident success',
    Gaiata: 'Earth power, physical strength, vitality',
    Lunata: 'Power of dreams, unconscious strength',
    Thanata: 'Power through surrender, strength in letting go'
  },
  'Siva': {
    Solara: 'Death of ego into divine light, ultimate liberation',
    Lyrata: 'Songs of death/rebirth, voices of ancestors',
    Erotica: 'Death goddess energy, orgasmic ego death, erotic rebirth',
    Celesta: 'Angelic guidance through death process',
    Corona: 'Compassionate transformation, heart-centered release',
    Fortuna: 'Death into abundance, inheritance wisdom',
    Gaiata: 'Physical death feeding new life, natural cycles',
    Lunata: 'Death realm dreams, unconscious transformation',
    Thanata: 'Pure death mysteries, complete void experience'
  }
};

const getLunarPhase = (dayNumber, monthOffset) => {
  const cycleLength = 29.5;
  const adjustedDay = dayNumber - monthOffset;
  const phase = (adjustedDay % cycleLength) / cycleLength;
  
  if (phase < 0.05 || phase > 0.95) return { type: 'new moon', icon: '⬤', value: 1.0 };
  if (phase > 0.45 && phase < 0.55) return { type: 'full moon', icon: '○', value: 1.0 };
  if (phase < 0.25) return { type: 'waxing crescent', icon: '◐', value: 0.25 };
  if (phase < 0.45) return { type: 'first quarter', icon: '◑', value: 0.5 };
  if (phase < 0.75) return { type: 'waning gibbous', icon: '◐', value: 0.25 };
  if (phase < 0.95) return { type: 'last quarter', icon: '◑', value: 0.5 };
  return { type: 'waning crescent', icon: '◐', value: 0.25 };
};

const getZodiacSign = (dayNumber) => {
  const transition = ASTRONOMICAL_EVENTS.zodiacTransitions.find(z => z.day === dayNumber);
  if (transition) return transition.sign;
  
  const lastTransition = [...ASTRONOMICAL_EVENTS.zodiacTransitions]
    .reverse()
    .find(z => z.day < dayNumber);
  
  return lastTransition?.sign || '♑';
};

const getJulianDate = (dayNumber) => {
  const baseDate = new Date(2024, 11, 21);
  const date = new Date(baseDate.getTime() + (dayNumber - 1) * 24 * 60 * 60 * 1000);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
};

const getSolarGradient = (dayNumber) => {
  const solarEvent = ASTRONOMICAL_EVENTS.solarEvents.find(e => Math.abs(e.day - dayNumber) <= 3);
  if (!solarEvent) return 0;
  
  const daysDiff = Math.abs(solarEvent.day - dayNumber);
  const gradients = { 0: 1.0, 1: 0.75, 2: 0.50, 3: 0.25 };
  return gradients[daysDiff] || 0;
};

const getStellarGradient = (dayNumber) => {
  const stellarEvent = ASTRONOMICAL_EVENTS.stellarEvents.find(e => Math.abs(e.day - dayNumber) <= 3);
  if (!stellarEvent) return 0;
  
  const daysDiff = Math.abs(stellarEvent.day - dayNumber);
  const gradients = { 0: 1.0, 1: 0.75, 2: 0.50, 3: 0.25 };
  return gradients[daysDiff] || 0;
};

const getDayMeaning = (monthName, catDayName) => {
  const baseMonthName = monthName.split(' ')[0];
  return DAY_MEANINGS[baseMonthName]?.[catDayName] || 'No meaning available';
};

const getSeasonalFrequency = (dayNumber) => {
  if (dayNumber >= 276) return 963; // Winter
  if (dayNumber >= 183) return 741; // Fall
  if (dayNumber >= 90) return 528;  // Summer
  return 396; // Spring
};

const getBasicHarmony = (freq1, freq2) => {
  const ratio = freq1 / freq2;
  if (Math.abs(ratio - 1) < 0.01) return { value: 2.0, desc: 'perfect intervals' };
  if (Math.abs(ratio - 1.5) < 0.01) return { value: 1.5, desc: 'strong intervals' };
  if (Math.abs(ratio - 1.333) < 0.01) return { value: 1.0, desc: 'good intervals' };
  return { value: 0.5, desc: 'mixed intervals' };
};

const getTripleHarmony = (day, monthData, seasonFreq) => {
  const frequencies = [day.catDay.freq, monthData.baseFreq, seasonFreq];
  const uniqueFreqs = new Set(frequencies).size;
  
  if (uniqueFreqs === 1) return { value: 4.0, desc: 'perfect alignment' };
  if (uniqueFreqs === 2) return { value: 3.0, desc: 'strong alignment' };
  if (uniqueFreqs === 3 && frequencies.every(f => f > 500)) {
    return { value: 2.0, desc: 'good alignment' };
  }
  if (uniqueFreqs === 3 && frequencies.every(f => f > 300)) {
    return { value: 1.0, desc: 'basic alignment' };
  }
  if (uniqueFreqs === 3 && frequencies.some(f => f < 300)) {
    return { value: 0.5, desc: 'challenging alignment' };
  }
  return { value: 0.25, desc: 'very challenging alignment' };
};

export default function HolofelineCalendar() {
  const [currentMonth, setCurrentMonth] = useState(0);
  const [selectedDay, setSelectedDay] = useState(null);

  const calculatePower = (day, monthData) => {
    const seasonFreq = getSeasonalFrequency(day.dayNumber);
    const powerFactors = {
      tripleHarmony: {
        first: getTripleHarmony(day, monthData, seasonFreq),
        second: day.isCatwalk ? getTripleHarmony({
          catDay: CAT_DAYS[0],
          dayNumber: 1
        }, MONTHS[0], seasonFreq) : null
      },
      basicHarmony: {
        first: getBasicHarmony(day.catDay.freq, monthData.baseFreq),
        second: day.isCatwalk ? getBasicHarmony(963, 963) : null
      },
      lunar: getLunarPhase(day.dayNumber, monthData.offset).value,
      solar: getSolarGradient(day.dayNumber),
      stellar: getStellarGradient(day.dayNumber)
    };

    let total = powerFactors.tripleHarmony.first.value + 
                powerFactors.basicHarmony.first.value;
                
    if (day.isCatwalk) {
      total += powerFactors.tripleHarmony.second.value + 
               powerFactors.basicHarmony.second.value;
    }
    
    total += powerFactors.lunar + powerFactors.solar + powerFactors.stellar;

    return {
      factors: powerFactors,
      total,
      rating: Math.round((total / 9) * 100)
    };
  };

  const generateMonthDays = (monthIndex) => {
    const days = [];
    const month = MONTHS[monthIndex];
    const startDay = month.startDay;
    const daysInMonth = monthIndex === 11 ? 6 : 33;
    
    for (let i = 0; i < month.offset; i++) {
      days.push({ isEmpty: true });
    }

    for (let i = 0; i < daysInMonth; i++) {
      const dayNumber = startDay + i;
      const catDayIndex = (month.offset + i) % 9;
      const isCatwalk = dayNumber >= 366;

      days.push({
        dayNumber,
        catDay: CAT_DAYS[catDayIndex],
        isCatwalk,
        isEmpty: false,
        date: getJulianDate(dayNumber),
        weekday: getWeekday(dayNumber)
      });
    }

    return days;
  };

  return (
    <div className="max-w-6xl mx-auto p-4 bg-white">
      <div className="mb-6 flex justify-between items-start">
        <h1 className="text-3xl font-bold">Holofeline Calendar</h1>
        <div className="text-right text-sm">
          <div className="font-semibold">Year of the Crown Cat (2024-2025)</div>
          <div className="text-gray-600">Based on the 9 Chakra Harmonics</div>
        </div>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <button
          className="px-4 py-2 bg-purple-100 hover:bg-purple-200 rounded"
          onClick={() => setCurrentMonth(prev => Math.max(0, prev - 1))}
        >
          Previous Month
        </button>
        <div className="text-center">
          <h2 className="text-xl font-bold">{MONTHS[currentMonth].name} • {MONTHS[currentMonth].baseFreq}Hz</h2>
          <div className="text-gray-600">{MONTHS[currentMonth].dateRange}</div>
        </div>
        <button
          className="px-4 py-2 bg-purple-100 hover:bg-purple-200 rounded"
          onClick={() => setCurrentMonth(prev => Math.min(11, prev + 1))}
        >
          Next Month
        </button>
      </div>

    <div className="grid grid-cols-9 gap-1">
  {CAT_DAYS.map(day => (
    <div key={day.name} className="p-2 text-center font-bold bg-gray-50 rounded">
      <div className="text-lg">{day.name}</div>
      <div className="text-sm text-gray-600">{day.chakra}</div>
      <div className="text-xs">{day.freq}Hz • {day.note}</div>
    </div>
  ))}

        {generateMonthDays(currentMonth).map((day, index) => (
          <div
            key={index}
            onClick={() => !day.isEmpty && setSelectedDay(day)}
            className={`
              p-2 text-center border rounded min-h-[120px]
              ${day.isEmpty ? 'bg-gray-50' : 'hover:bg-gray-100 cursor-pointer'}
              ${day.isCatwalk ? 'bg-purple-50 border-purple-200' : ''}
            `}
            style={!day.isEmpty ? { backgroundColor: `${day.catDay.color}10` } : {}}
          >
            {!day.isEmpty && (
              <div className="space-y-1 text-xs">
                <div className="font-bold">{day.dayNumber}</div> 
                <div className="font-bold">{day.catDay.name}</div>
                <div>{day.weekday}</div>
                <div>{day.date}</div>
                <div>{getZodiacSign(day.dayNumber)} {getLunarPhase(day.dayNumber, MONTHS[currentMonth].offset).icon}</div>
  {day.isCatwalk && (
  <>
    {day.dayNumber === 366 && <div className="text-orange-600">Winter Solstice</div>}
    <div className="text-purple-600">Day {day.dayNumber}/{day.dayNumber - 365}</div>
    <div className="text-purple-600">
      {day.catDay.name}/{
        day.dayNumber === 366 ? 'Solara' :
        day.dayNumber === 367 ? 'Celesta' :
        day.dayNumber === 368 ? 'Lunata' :
        'Lyrata'
      }
    </div>
  </>
)}

 






                {getSolarGradient(day.dayNumber) > 0 && !day.isCatwalk && (
                  <div className="text-orange-600">
                    {ASTRONOMICAL_EVENTS.solarEvents.find(e => Math.abs(e.day - day.dayNumber) <= 0)?.type}
                  </div>
                )}
                {getStellarGradient(day.dayNumber) > 0 && (
                  <div className="text-blue-600">
                    {ASTRONOMICAL_EVENTS.stellarEvents.find(e => Math.abs(e.day - day.dayNumber) <= 0)?.type}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>


 {/* Power Calculation Display */}
      {selectedDay && !selectedDay.isEmpty && (() => {
        const power = calculatePower(selectedDay, MONTHS[currentMonth]);
        return (
          <div className="mt-4 p-4 bg-gray-50 rounded shadow-sm">
            <h3 className="text-lg font-bold mb-4">
              {selectedDay.isCatwalk ? 
                `First Paw of the Catwalk (${selectedDay.date})` : 
                `Day ${selectedDay.dayNumber} Details`}
            </h3>
              <div className="text-sm italic text-gray-600 mb-4">
              {getDayMeaning(MONTHS[currentMonth].name, selectedDay.catDay.name)}
           </div>
            
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-4">
                <div>
                  <h4 className="font-semibold">Day {selectedDay.dayNumber} Calculations:</h4>
                  <p className="text-sm mt-1">
                    {selectedDay.catDay.name} ({selectedDay.catDay.freq}Hz) in {MONTHS[currentMonth].name} 
                    ({MONTHS[currentMonth].baseFreq}Hz) during {
                      selectedDay.dayNumber >= 276 ? 'Winter' :
                      selectedDay.dayNumber >= 183 ? 'Fall' :
                      selectedDay.dayNumber >= 90 ? 'Summer' : 'Spring'
                    } ({getSeasonalFrequency(selectedDay.dayNumber)}Hz)
                  </p>
                  <div className="mt-2">
                    <p>Triple Harmony: {power.factors.tripleHarmony.first.value} 
                      <span className="text-gray-600 text-sm"> ({power.factors.tripleHarmony.first.desc})</span>
                    </p>
                    <p>Basic Harmony: {power.factors.basicHarmony.first.value}
                      <span className="text-gray-600 text-sm"> ({power.factors.basicHarmony.first.desc})</span>
                    </p>
                  </div>
                </div>

                {selectedDay.isCatwalk && (
                  <div>
                    <h4 className="font-semibold">Day 001 Calculations:</h4>
                    <p className="text-sm mt-1">Solara (963Hz) in Saha I (963Hz) during Winter (963Hz)</p>
                    <div className="mt-2">
                      <p>Triple Harmony: {power.factors.tripleHarmony.second.value}
                        <span className="text-gray-600 text-sm"> ({power.factors.tripleHarmony.second.desc})</span>
                      </p>
                      <p>Basic Harmony: {power.factors.basicHarmony.second.value}
                        <span className="text-gray-600 text-sm"> ({power.factors.basicHarmony.second.desc})</span>
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="space-y-4">
                <div>
                  <h4 className="font-semibold">Shared Factors:</h4>
                  <p>Lunar: {power.factors.lunar}</p>
                  <p>Solar: {power.factors.solar}</p>
                  <p>Stellar: {power.factors.stellar}</p>
                </div>

                <div>
                  <h4 className="font-semibold">Total Calculation:</h4>
                  {selectedDay.isCatwalk ? (
                    <p className="text-sm">({power.factors.tripleHarmony.first.value} + {power.factors.basicHarmony.first.value}) + 
                       ({power.factors.tripleHarmony.second.value} + {power.factors.basicHarmony.second.value}) + 
                       {power.factors.lunar} + {power.factors.solar} + {power.factors.stellar} = {power.total}</p>
                  ) : (
                    <p className="text-sm">{power.factors.tripleHarmony.first.value} + {power.factors.basicHarmony.first.value} + 
                       {power.factors.lunar} + {power.factors.solar} + {power.factors.stellar} = {power.total}</p>
                  )}
                  <p className="text-sm">{power.total} / 9 = {(power.total/9).toFixed(2)}</p>
                  <p className="font-bold text-lg mt-2">Power Rating: {power.rating}%</p>
                </div>
              </div>
            </div>
          </div>
        );
      })()}
    </div>
  );
}